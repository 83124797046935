import "../Landingpage/landing.css";
import Image from "next/image";
import Logo from "../../Helper/images/zonaktech.svg";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { useState, useEffect } from "react";
import { loadedAction } from "@/app/[locale]/loadedAction";
import { useTranslations } from "next-intl";

export default function Landingpage({ setlanding }) {
  const [currentVideo, setCurrentVideo] = useState("mechanical");
  const [showContent, setShowContent] = useState(false);
  
  const t1 = useTranslations("mechlandingscreen");
  const t2 = useTranslations("electriclandingscreen");
  const t3 = useTranslations("waterlandingscreen");
  const [videoCycleCount, setVideoCycleCount] = useState(0);
  const [redirecting, setRedirecting] = useState(false); 
  

  useEffect(() => {
    const initialCycleDuration = 3 * 1000; 
    const contentCycleDuration = 2 * 1000; 
    const totalInitialDuration = 9 * 1000; 

    const initialCycleTimer = setInterval(() => {
      setCurrentVideo((prev) => {
        if (prev === "mechanical") return "electric";
        if (prev === "electric") return "water";
        if (prev === "water") return "mechanical";
      });
    }, initialCycleDuration);

    const showContentTimer = setTimeout(() => {
      clearInterval(initialCycleTimer); 
      setShowContent(true); 

      const contentCycleTimer = setInterval(() => {
        setCurrentVideo((prev) => {
          if (prev === "mechanical") return "electric";
          if (prev === "electric") return "water";
          if (prev === "water") {
            redirectToHomePage(); 
            return "mechanical";
          }
        });
      }, contentCycleDuration);

      return () => clearInterval(contentCycleTimer);
    }, totalInitialDuration);

    return () => {
      clearInterval(initialCycleTimer);
      clearTimeout(showContentTimer);
    };
  }, []);

  const redirectToHomePage = () => {
    if (!redirecting) {
      setRedirecting(true);
      setlanding(false);
      loadedAction();
      console.log("Redirecting to home page after one full cycle with content");
    }
  };


  const mechnicallanding = [
    { id: 1, list: t1("list1") },
    { id: 2, list: t1("list2") },
    { id: 3, list: t1("list3") },
    { id: 4, list: t1("list4") },
  ];

  const electriclanding = [
    { id: 1, list: t2("list1") },
    { id: 2, list: t2("list2") },
    { id: 3, list: t2("list3") },
    { id: 4, list: t2("list4") },
  ];

  const waterlanding = [
    { id: 1, list: t3("list1") },
    { id: 2, list: t3("list2") },
    { id: 3, list: t3("list3") },
    { id: 4, list: t3("list4") },
  ];


  const redirectpage = () => {
    setlanding(false);
    console.log("Redirecting to home page");
  };

  return (
    <div className={showContent ? "showcontetsec Landingpage" : "Landingpage"}>
      <div className="chart">
        <div id="browse-IE" className="hold pieone">
          <div className="pie">
            {currentVideo === "mechanical" && !redirecting && (
              <video
                autoPlay
                muted
                loop
                width={320}
                height={210}
                className="banner_video2"
                alt="Mechanical Video"
              >
                <source src="/video/mechanicalvideo.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
        <div id="browse-FF" className="hold">
          <div className="pie">
            {currentVideo === "water" && !redirecting && (
              <video
                autoPlay
                muted
                loop
                width={320}
                height={210}
                className="banner_video2"
                alt="Water Video"
              >
                <source src="/video/watervideo.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
        <div id="browse-Unknown" className="hold">
          <div className="pie">
            {currentVideo === "electric" && !redirecting && (
              <video
                autoPlay
                muted
                loop
                width={320}
                height={210}
                className="banner_video2"
                alt="Electric Video"
              >
                <source src="/video/electricalvideo.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
        <div className="mobilresp">
          {showContent && currentVideo === "mechanical" && !redirecting && (
            <div className="mechanicallanding">
               <small>{t1("Mechanical")}</small>
              {mechnicallanding.map((item) => (
                <ul key={item.id}>
                  <li>{item.list}</li>
                </ul>
              ))}
            </div>
          )}

          {showContent && currentVideo === "electric" && !redirecting && (
            <div className="electricallanding">
             <small>{t1("Electrical")}</small>
              {electriclanding.map((item) => (
                <ul key={item.id}>
                  <li>{item.list}</li>
                </ul>
              ))}
            </div>
          )}

          {showContent && currentVideo === "water" && !redirecting && (
            <div className="waterlanding">
               <small>{t1("Water")}</small>
              {waterlanding.map((item) => (
                <ul key={item.id}>
                  <li>{item.list}</li>
                </ul>
              ))}
            </div>
          )}
        </div>
      </div>

      {showContent && (
        <div className="desktopres">
          {currentVideo === "mechanical" && !redirecting && (
            <div className="mechanicallanding">
               <small>{t1("Mechanical")}</small>
              {mechnicallanding.map((item) => (
                <ul key={item.id}>
                  <li>{item.list}</li>
                </ul>
              ))}
            </div>
          )}

          {currentVideo === "electric" && !redirecting && (
            <div className="electricallanding">
             <small>{t1("Electrical")}</small>
              {electriclanding.map((item) => (
                <ul key={item.id}>
                  <li>{item.list}</li>
                </ul>
              ))}
            </div>
          )}

          {currentVideo === "water" && !redirecting && (
            <div className="waterlanding">
               <small>{t1("Water")}</small>
              {waterlanding.map((item) => (
                <ul key={item.id}>
                  <li>{item.list}</li>
                </ul>
              ))}
            </div>
          )}
        </div>
      )}

      <div className={showContent ? "contentshowing" : "sssss"}>
        <div className="landing_imgs">
          <Image src={Logo} alt="logo" width={130} height={72} className="nav_logo" />
        </div>


        <div onClick={redirectpage}>
          <h3>
          {t1("skip")} <EastOutlinedIcon />
          </h3>
        </div>
      </div>
    </div>

  );
}

